import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Rail from 'components/Rail'
import styles from 'components/RailLeft.module.sass'
import { actions } from 'modules/home/HomeReducer'
import {
  getSpecialtyInput,
  getCurricula,
  getPodcasts,
  getEvents,
  getMediaUrlPSource
} from 'modules/home/HomeSelector'
import {
  getIsLogin,
  getProfile,
  getHasPartyId
} from 'modules/auth/AuthSelector'
import { getProfileInfo } from 'utils'

const RailLeft = () => {
  const dispatch = useDispatch()

  const [curricula, setCurricula] = useState([])
  const [podcast, setPodcast] = useState([])
  const [event, setEvent] = useState([])

  const dataCurricula = useSelector(getCurricula)
  const dataPodcast = useSelector(getPodcasts)
  const dataEvent = useSelector(getEvents)

  useEffect(() => {
    dispatch(actions.requestCurricula())
    dispatch(actions.requestPodcasts())
    dispatch(actions.requestEvents())
  }, [dispatch])

  // Specialty
  const specialtyInput = useSelector(getSpecialtyInput)

  useEffect(() => {
    setCurricula(
      dataCurricula?.filter(f =>
        f.specialties.includes(specialtyInput.label)
      ) ?? []
    )
    setPodcast(
      dataPodcast?.filter(f => f.specialties.includes(specialtyInput.label)) ??
        []
    )
    setEvent(
      dataEvent?.filter(f => f.specialties.includes(specialtyInput.label)) ?? []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dataCurricula, dataPodcast, dataEvent, specialtyInput.value])

  const mediaUrlPSource = useSelector(getMediaUrlPSource)
  const hasPartyId = useSelector(getHasPartyId)
  const isLoggedIn = useSelector(getIsLogin)
  const profile = useSelector(getProfile)
  let [urlParams, setUrlParams] = useState(
    `?SpecialtyID=&AudienceID=aicme&PromoCode=796${String(mediaUrlPSource)}`
  )
  useEffect(() => {
    if (isLoggedIn && profile) {
      const {
        partyId,
        partyEmailId,
        professionId,
        specialtyId
      } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&EmailID=${partyEmailId}&ProfessionID=${professionId}&SpecialtyID=${specialtyId}&AudienceID=aicme&PromoCode=796`
      )
    } else if (hasPartyId && profile) {
      const { partyId } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&AudienceID=aicme&PromoCode=796${String(
          mediaUrlPSource
        )}`
      )
    }
  }, [isLoggedIn, hasPartyId, profile, mediaUrlPSource])

  return (
    <Rail styles={styles}>
      <div className={styles.content}>
        <div className={styles.menu}>
          <p className={styles.menuTitle}>CURRICULA</p>
        </div>
        <div className={styles.menuC}>
          <p className={styles.menuText} style={{ paddingBottom: '19px' }}>
            A curriculum is a series of related courses
          </p>
          {curricula.map(({ title, url }) => (
            <a
              href={url + urlParams}
              key={title}
              target="_blank"
              rel="noreferrer"
            >
              <p className={styles.link}>{title}</p>
            </a>
          ))}
          <Link
            to="/curricula"
            className={styles.moreLink}
            onClick={() => {
              dispatch(actions.setSearchTerm(''))
              dispatch(actions.setSearchPanel(false))
            }}
          >
            <p className={styles.moreText}>View More</p>
            <p style={{ height: '22px' }}>&nbsp;</p>
          </Link>
        </div>
        <div className={styles.menu}>
          <p className={styles.menuTitle}>PODCASTS</p>
        </div>
        <div className={styles.menuC}>
          {podcast.map(({ channel, distributions }) => (
            <div key={channel} className={styles.span}>
              <p className={styles.menuText}>{channel}</p>
              {distributions
                .map(({ name, url }) => (
                  <a
                    key={name}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.link}
                  >
                    {name}
                  </a>
                ))
                .reduce((prev, curr) => [prev, ' | ', curr])}
            </div>
          ))}
          <Link
            to="/podcasts"
            className={styles.moreLink}
            onClick={() => {
              dispatch(actions.setSearchTerm(''))
              dispatch(actions.setSearchPanel(false))
            }}
          >
            <p className={styles.moreText}>View More</p>
            <p style={{ height: '22px' }}>&nbsp;</p>
          </Link>
        </div>
        <div className={styles.menu}>
          <p className={styles.menuTitle}>LIVE EVENTS</p>
        </div>
        <div className={styles.menuC}>
          {event.map(({ title, url, dates, format, location, credit }) => (
            <div key={title}>
              <a href={url + urlParams} target="_blank" rel="noreferrer">
                <p className={styles.link} style={{ marginBottom: '6px' }}>
                  {title}
                </p>
              </a>
              {dates && (
                <p className={styles.info}>
                  Date:{' '}
                  <span style={{ color: '#000', whiteSpace: 'pre-line' }}>
                    {dates.join(`\n`)}
                  </span>
                </p>
              )}
              {location && (
                <p className={styles.info}>
                  Location: <span style={{ color: '#000' }}>{location}</span>
                </p>
              )}
              {credit && (
                <p className={styles.info} style={{ marginBottom: '19px' }}>
                  Credit: <span style={{ color: '#000' }}>{credit}</span>
                </p>
              )}
            </div>
          ))}
          <Link
            to="/live-events"
            className={styles.moreLink}
            onClick={() => {
              dispatch(actions.setSearchTerm(''))
              dispatch(actions.setSearchPanel(false))
            }}
          >
            <p className={styles.moreText}>View More</p>
            <p style={{ height: '22px' }}>&nbsp;</p>
          </Link>
        </div>
      </div>
    </Rail>
  )
}

export default RailLeft
