import { useHistory } from 'react-router-dom'
import FormLayout from 'layouts/FormLayout'
import Svg from 'react-inlinesvg'
import './style.css'

import styles from './SignUp.module.sass'
import config from 'site.config'

const { sections } = config

const SignUpSuccess = () => {
  const history = useHistory()
  const { email } = sections.privacy

  return (
    <FormLayout loading={false} disableLottie={true}>
      <div className={styles.box}>
        <div className={styles.modal}>
          <p>
            Thank you for your interest in <strong>Answers In CME</strong>.
          </p>
          <p>
            We have received your registration request and will contact you
            shortly.
          </p>
          <p>
            For any further assistance, do not hesitate to{' '}
            <a href={'mailto:' + email}>contact us</a>.
          </p>
        </div>
        <button
          className={styles.back}
          onClick={() => history.goBack()}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') history.goBack()
          }}
        >
          <p
            style={{
              color: '#979797',
              fontSize: '14px',
              fontWeight: 'bold',
              lineHeight: '17px'
            }}
          >
            <Svg
              src="/img/chevron-left.svg"
              height={12}
              width={7}
              fill={'#979797'}
              style={{ marginRight: '4.3px' }}
            />
            Back
          </p>
        </button>
      </div>
    </FormLayout>
  )
}

export default SignUpSuccess
