import styles from './Ad.module.sass'
import AdCard from './AdCard'
import { getRandomInt } from 'utils'

const Ad = props => {
  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>COURSES FOR YOU</h2>
      <AdCard
        type="home"
        position="centerrail"
        random={getRandomInt(10000, 99999).toString()}
      />
    </div>
  )
}

Ad.propTypes = {}

export default Ad
