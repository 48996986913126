import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import LoadingDots from 'components/LoadingDots'
import styles from './Interest.module.sass'
import InterestCard from './InterestCard'
import { actions } from 'modules/home/HomeReducer'
import {
  getSpecialtyInput,
  getInterest,
  getMediaUrlPSource
} from 'modules/home/HomeSelector'
import {
  getIsLogin,
  getProfile,
  getHasPartyId
} from 'modules/auth/AuthSelector'
import { getProfileInfo } from 'utils'
import config from 'site.config'

const {
  configuration: { interestFeed }
} = config
const pageSize = interestFeed.query.maxResults

const Interest = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [interest, setInterest] = useState([])
  const {
    data,
    loading: isLoading,
    pages,
    total,
    currentPage: currentFetchedPage
  } = useSelector(getInterest)
  const isLastPage = currentPage === pages

  useEffect(() => {
    const isCurrentPageDataFetched = currentFetchedPage === currentPage
    const isLatestDataAddedToList = interest.length < currentPage * pageSize

    if (!isLoading && isCurrentPageDataFetched && isLatestDataAddedToList) {
      if (currentPage === 1) setInterest([...data])
      else setInterest([...interest, ...data])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentFetchedPage, data, isLoading])

  // Specialty
  const specialtyInput = useSelector(getSpecialtyInput)
  useEffect(() => {
    setCurrentPage(1)
    setInterest([])
    let payload = {
      specialty: specialtyInput?.value2 || 'General Medicine',
      currentPage: 1
    }
    dispatch(actions.requestInterest(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, specialtyInput])

  const mediaUrlPSource = useSelector(getMediaUrlPSource)
  const hasPartyId = useSelector(getHasPartyId)
  const isLoggedIn = useSelector(getIsLogin)
  const profile = useSelector(getProfile)
  let [urlParams, setUrlParams] = useState(
    `?SpecialtyID=&AudienceID=aicme&PromoCode=796${String(mediaUrlPSource)}`
  )
  useEffect(() => {
    if (isLoggedIn && profile) {
      const {
        partyId,
        partyEmailId,
        professionId,
        specialtyId
      } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&EmailID=${partyEmailId}&ProfessionID=${professionId}&SpecialtyID=${specialtyId}&AudienceID=aicme&PromoCode=796`
      )
    } else if (hasPartyId && profile) {
      const { partyId } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&AudienceID=aicme&PromoCode=796${String(
          mediaUrlPSource
        )}`
      )
    }
  }, [isLoggedIn, hasPartyId, profile, mediaUrlPSource])

  const loadMore = () => {
    setCurrentPage(currentPage + 1)
    let payload = {
      specialty: specialtyInput?.value2 || 'General Medicine',
      currentPage: currentPage + 1
    }
    dispatch(actions.requestInterest(payload))
  }

  return (
    <div className={styles.container}>
      {total > 0 &&
        interest?.map(({ id, title, teaser, extra_fields }) => (
          <InterestCard
            key={id}
            thumbnail={
              extra_fields.thumbnail ||
              extra_fields.icon_image ||
              'https://c.answersincme.com/assets/images/aicme-default-thumbnail.jpg'
            }
            credit={Number(extra_fields.dgme_credits).toFixed(1)}
            creditType={extra_fields.dgme_credit_type}
            title={title}
            description={teaser}
            url={extra_fields.article_url + urlParams}
            alt={extra_fields.human_url}
          />
        ))}
      {isLoading && <LoadingDots />}
      {!isLastPage && total > 0 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            className={cx(styles.button, isLoading && styles.disable)}
            onClick={() => loadMore()}
          >
            Show More <ArrowDropDownRoundedIcon sx={styles.buttonIcon} />
          </button>
        </div>
      )}
    </div>
  )
}

Interest.propTypes = {}

export default Interest
