import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import LoadingDots from 'components/LoadingDots'

import ActivityCard from 'components/ActivityCard'
import styles from './SuggestedProgram.module.sass'

import { actions } from 'modules/cme-tracker/CMETrackerReducer'
import {
  getSuggestedPrograms,
  getCertList
} from 'modules/cme-tracker/CMETrackerSelector'
import { getMediaUrlPSource } from 'modules/home/HomeSelector'
import {
  getIsLogin,
  getProfile,
  getHasPartyId
} from 'modules/auth/AuthSelector'
import config from 'site.config'

const {
  configuration: { suggestedPrograms }
} = config
const pageSize = suggestedPrograms.query.maxResults
// const exceed_1y = 365 * 24 * 60 * 60 * 1000 // Miliseconds in a year

export const getProfileInfo = profile => {
  const partyId = get(profile, 'info.user.partyId', '')
  const partyEmailId = get(profile, 'info.user.partyEmailId', '')
  const professionId = get(profile, 'info.user.professionId', '')
  const specialtyId = get(profile, 'info.user.specialtyId', '')
  return { partyId, partyEmailId, professionId, specialtyId }
}

const SuggestedProgram = props => {
  const dispatch = useDispatch()

  const mediaUrlPSource = useSelector(getMediaUrlPSource)
  const hasPartyId = useSelector(getHasPartyId)
  const isLoggedIn = useSelector(getIsLogin)
  const profile = useSelector(getProfile)
  let [urlParams, setUrlParams] = useState(
    `?SpecialtyID=&AudienceID=aicme&PromoCode=796${String(mediaUrlPSource)}`
  )
  useEffect(() => {
    if (isLoggedIn && profile) {
      const {
        partyId,
        partyEmailId,
        professionId,
        specialtyId
      } = getProfileInfo(profile)
      dispatch(actions.requestSuggestedPrograms({ currentPage: 1 }))
      setUrlParams(
        `?MemberID=${partyId}&EmailID=${partyEmailId}&ProfessionID=${professionId}&SpecialtyID=${specialtyId}&AudienceID=aicme&PromoCode=796`
      )
    } else if (hasPartyId && profile) {
      const { partyId } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&AudienceID=aicme&PromoCode=796${String(
          mediaUrlPSource
        )}`
      )
    }
  }, [dispatch, isLoggedIn, hasPartyId, profile, mediaUrlPSource])

  const [currentPage, setCurrentPage] = useState(1)
  const [activities, setActivities] = useState([])
  const completedPrograms = useSelector(getCertList)
  const {
    data,
    loading: isLoading,
    pages,
    total,
    currentPage: currentFetchedPage
  } = useSelector(getSuggestedPrograms)
  const completedProgramNumbers = useMemo(
    () => completedPrograms?.map(prog => prog.program),
    [completedPrograms]
  )
  const isLastPage = currentPage === pages
  const isLastRecord = activities.length === total

  useEffect(() => {
    const isCurrentPageDataFetched = currentFetchedPage === currentPage
    const isLatestDataAddedToList = activities.length < currentPage * pageSize

    if (
      !isLoading &&
      isCurrentPageDataFetched &&
      isLatestDataAddedToList &&
      !isLastRecord
    ) {
      const programs = data.filter(
        data => !completedProgramNumbers?.includes(data.id)
      )
      const newActivities = [...activities, ...programs]
      setActivities(newActivities)
    }
  }, [
    activities,
    currentPage,
    currentFetchedPage,
    data,
    isLastRecord,
    isLoading,
    completedProgramNumbers
  ])

  useEffect(() => {
    dispatch(actions.requestSuggestedPrograms({ currentPage }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    window.onscroll = () => {
      const bottomOfWindow =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.body.offsetHeight
      if (!isLoading && bottomOfWindow && !isLastPage && currentPage < pages) {
        setCurrentPage(currentPage + 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isLastPage, isLoading])

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>EARN ADDITIONAL CREDIT</h2>

      <div className={styles.grid}>
        {activities.map(
          ({
            id,
            title,
            url,
            authors,
            expiration_date,
            date,
            type,
            publishers,
            image,
            imageFallback,
            credit,
            credit_type
          }) => {
            return (
              <ActivityCard
                title={title}
                url={url + urlParams}
                date={date}
                thumbnail={
                  image ||
                  imageFallback ||
                  'https://c.answersincme.com/assets/images/aicme-default-thumbnail.jpg'
                }
                authors={authors}
                creditType={credit_type}
                credit={credit}
                key={id + ' ' + date}
                alt={title}
              />
            )
          }
        )}
      </div>
      {isLoading && <LoadingDots />}
      {!isLoading && !activities.length && (
        <p className={styles.noresult}>No activity found</p>
      )}
    </div>
  )
}

SuggestedProgram.propTypes = {}

export default SuggestedProgram
