import { createSelector } from '@reduxjs/toolkit'

import Session from 'services/SessionService'

export const homeSelector = state => state.homeReducer

export const getLoading = createSelector(homeSelector, res => res.loading)

// Search
export const getSearchTerm = createSelector(homeSelector, res => res.searchTerm)
export const getSearchPanel = createSelector(
  homeSelector,
  res => res.searchPanel
)
export const getSearch = createSelector(homeSelector, res => res.search)

// Article
export const getLoadingArticle = createSelector(
  homeSelector,
  res => res.loadingArticle
)
export const getArticle = createSelector(homeSelector, res => res.article)

// News
export const getLoadingNews = createSelector(
  homeSelector,
  res => res.loadingNews
)
export const getNews = createSelector(homeSelector, res => res.news)

// Interest
export const getLoadingInterest = createSelector(
  homeSelector,
  res => res.loadingInterest
)
export const getInterest = createSelector(homeSelector, res => res.interest)

// Curricula
export const getLoadingCurricula = createSelector(
  homeSelector,
  res => res.loadingCurricula
)
export const getCurricula = createSelector(homeSelector, res => res.curricula)

// Podcasts
export const getLoadingPodcasts = createSelector(
  homeSelector,
  res => res.loadingPodcasts
)
export const getPodcasts = createSelector(homeSelector, res => res.podcasts)

// Events
export const getLoadingEvents = createSelector(
  homeSelector,
  res => res.loadingEvents
)
export const getEvents = createSelector(homeSelector, res => res.events)

// Ads
export const getAd = createSelector(homeSelector, res => res.ad)

// Specialty
export const getLoadingSpecialty = createSelector(
  homeSelector,
  res => res.loadingSpecialty
)
export const getSpecialtyInput = createSelector(
  homeSelector,
  res => res?.specialtyInput || {}
)
export const getSpecialties = createSelector(
  homeSelector,
  res => res.specialties?.filter(f => !f.hideFilter) || []
)

export const getMediaUrlPSource = createSelector(homeSelector, res => {
  const session = new Session()
  const partySource = session.getFromCache('PartySource', '')
  const partySourceMapping = { Known: 1, New: 2, Returning: 3 }
  let partySourceForMediaUrl = ''
  if (partySource !== '') {
    partySourceForMediaUrl = partySourceMapping[partySource]
      ? `&pSource=${partySourceMapping[partySource]}`
      : ''
  }
  return partySourceForMediaUrl || ''
})
