import PropTypes from 'prop-types'
import StaticFileLayout from 'layouts/StaticFileLayout'

const Static = ({ title, filename }) => {
  return <StaticFileLayout title={title} filename={filename} />
}

Static.propTypes = {
  title: PropTypes.string,
  filename: PropTypes.string
}

export default Static
