/**
 * Constants used by the ParamsService.
 */

const urlParamsNonPersistent = [
  'AOMID',
  'AudienceID',
  'CampaignID',
  'CountryID',
  'ProfessionID',
  'Promocode',
  'SpecialtyID',
  'Language',
  'SearchTerm',
  'SearchSpecialty',
  'SearchTopic',
  'interactionId'
]

const urlParamsPersistent = {
  MemberID: 0,
  EmailID: 0
}

const urlParamsSynonyms = {
  AOMID: ['aomid'],
  AudienceID: ['audienceid', 'audienceID'],
  CampaignID: ['campaignid', 'Campaignid', 'campaignID'],
  CountryID: ['countryid', 'Countryid', 'countryID'],
  EmailID: [
    'emailid',
    'emailId',
    'partyemailid',
    'partyEmailId',
    'PartyEmailID',
    'PartyemailID'
  ],
  Language: ['language', 'languageCode'],
  MemberID: [
    'PersonID',
    'memberid',
    'memberId',
    'PartyID',
    'partyid',
    'partyId'
  ],
  ProfessionID: ['professionid', 'profession', 'ProfID', 'prof'],
  Promocode: ['promocode', 'PromoCode'],
  SpecialtyID: ['specialty', 'specialtyid'],
  interactionId: ['interactionId', 'interactionid']
}

const urlParamsNonPersistentDuration = 86400 // 24hrs

export {
  urlParamsNonPersistent,
  urlParamsPersistent,
  urlParamsSynonyms,
  urlParamsNonPersistentDuration
}
