import { Switch, Route } from 'react-router-dom'
import ScrollToTop from 'ScrollToTop'

import Home from 'modules/home'
import CMETracker from 'modules/cme-tracker'
import AboutUs from 'static/AboutUs'
import Partner from 'static/Partner'
import Static from 'static/Static'
import SignUp from 'modules/signup'
import SignUpSuccess from 'modules/signup/SignUpSuccess'
import ContactUs from 'modules/contactus/ContactUs'
import UserProfile from 'modules/userProfile'
import Unsubscribe from 'modules/unsubscribe'
import NotFound from 'static/NotFound'

const Routes = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Route path="/" default exact component={Home} />
        <Route path="/article/*" default component={Home} />
        <Route path="/curricula" default component={Home} />
        <Route path="/podcasts" default component={Home} />
        <Route path="/live-events" default component={Home} />
        <Route path="/cme-tracker" default exact component={CMETracker} />
        <Route path="/aboutus" default exact component={AboutUs} />
        <Route path="/about-us" default exact component={AboutUs} />
        <Route path="/partnership" default exact component={Partner} />
        <Route path="/partner-with-us" default exact component={Partner} />
        <Route
          path="/privacy"
          exact
          render={() => <Static title="Privacy Policy" filename="Privacy" />}
        />
        <Route
          path="/privacy-policy"
          exact
          render={() => <Static title="Privacy Policy" filename="Privacy" />}
        />
        <Route
          path="/privacy-de"
          exact
          render={() => (
            <Static title="Privacy Policy DE" filename="PrivacyDE" />
          )}
        />
        <Route
          path="/privacy-policy-de"
          exact
          render={() => (
            <Static title="Privacy Policy DE" filename="PrivacyDE" />
          )}
        />
        <Route
          path="/privacy-es"
          exact
          render={() => (
            <Static title="Privacy Policy ES" filename="PrivacyES" />
          )}
        />
        <Route
          path="/privacy-policy-es"
          exact
          render={() => (
            <Static title="Privacy Policy ES" filename="PrivacyES" />
          )}
        />
        <Route
          path="/privacy-fr"
          exact
          render={() => (
            <Static title="Privacy Policy FR" filename="PrivacyFR" />
          )}
        />
        <Route
          path="/privacy-policy-fr"
          exact
          render={() => (
            <Static title="Privacy Policy FR" filename="PrivacyFR" />
          )}
        />
        <Route
          path="/privacy-it"
          exact
          render={() => (
            <Static title="Privacy Policy IT" filename="PrivacyIT" />
          )}
        />
        <Route
          path="/privacy-policy-it"
          exact
          render={() => (
            <Static title="Privacy Policy IT" filename="PrivacyIT" />
          )}
        />
        <Route
          path="/privacy-de-eea"
          exact
          render={() => (
            <Static title="Privacy Policy DE EEA" filename="PrivacyDEeea" />
          )}
        />
        <Route
          path="/privacy-es-eea"
          exact
          render={() => (
            <Static title="Privacy Policy ES EEA" filename="PrivacyESeea" />
          )}
        />
        <Route
          path="/privacy-fr-eea"
          exact
          render={() => (
            <Static title="Privacy Policy FR EEA" filename="PrivacyFReea" />
          )}
        />
        <Route
          path="/privacy-it-eea"
          exact
          render={() => (
            <Static title="Privacy Policy IT EEA" filename="PrivacyITeea" />
          )}
        />
        <Route
          path="/corp-privacy"
          exact
          render={() => (
            <Static title="Corporate Privacy Policy" filename="PrivacyCorp" />
          )}
        />
        <Route
          path="/corp-emp-privacy"
          exact
          render={() => (
            <Static
              title="Corporate Employment Privacy Policy"
              filename="PrivacyCorpEmp"
            />
          )}
        />
        <Route
          path="/corp-eu"
          exact
          render={() => (
            <Static
              title="Corporate EU Privacy Policy"
              filename="PrivacyCorpEU"
            />
          )}
        />
        <Route
          path="/corp-eu-privacy"
          exact
          render={() => (
            <Static
              title="Corporate EU Privacy Policy"
              filename="PrivacyCorpEU"
            />
          )}
        />
        <Route
          path="/corp-ccpa"
          exact
          render={() => (
            <Static
              title="Corporate CCPA Privacy Policy"
              filename="PrivacyCorpCCPA"
            />
          )}
        />
        <Route
          path="/corp-ccpa-privacy"
          render={() => (
            <Static
              title="Corporate CCPA Privacy Policy"
              filename="PrivacyCorpCCPA"
            />
          )}
        />
        <Route
          path="/ccpa-privacy"
          exact
          render={() => (
            <Static title="CCPA Privacy Policy" filename="PrivacyCCPA" />
          )}
        />
        <Route
          path="/eu-privacy"
          exact
          render={() => (
            <Static title="EU Privacy Policy" filename="PrivacyEU" />
          )}
        />
        <Route
          path="/businesscontactprivacynotice"
          exact
          render={() => (
            <Static
              title="Business Contact Privacy Notice"
              filename="PrivacyBusiness"
            />
          )}
        />
        <Route
          path="/terms-of-use"
          exact
          render={() => <Static title="Terms of Use" filename="Terms" />}
        />
        <Route
          path="/terms-use"
          exact
          render={() => <Static title="Terms of Use" filename="Terms" />}
        />
        <Route
          path="/cookie"
          exact
          render={() => <Static title="Cookie Policy" filename="Cookie" />}
        />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/registration-thanks" exact component={SignUpSuccess} />
        <Route path="/feedback" exact component={ContactUs} />
        <Route path="/contactus" exact component={ContactUs} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/profile" exact component={UserProfile} />
        <Route path="/unsubscribe" exact component={Unsubscribe} />
        <Route path="*" component={NotFound} />
      </Switch>
    </ScrollToTop>
  )
}

export default Routes
